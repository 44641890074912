export function saveSessionToken(token: string) {
  localStorage.setItem('SESSION_TOKEN', token)
}

export function getSessionToken(): string | null {
  return localStorage.getItem('SESSION_TOKEN')
}

export function getSessionRefreshToken(): string | null {
  return localStorage.getItem('REFRESH_TOKEN')
}

export function saveSessionRefreshToken(token: string) {
  return localStorage.setItem('REFRESH_TOKEN', token)
}

export function saveUserId(userId: string) {
  return localStorage.setItem('USER_ID', userId)
}

export function getUserId() {
  return localStorage.getItem('USER_ID')
}

export function clearStorage() {
  localStorage.clear()
  sessionStorage.clear()
}
