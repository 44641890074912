import { Modal } from 'antd'
import { FC, ReactElement, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import Strings from '../../../common/services/localization-service'
import { LOGIN_ROUTE } from '../../routes'
import { showSessionExpiredDialog } from '../../slices/user-slice'
import { userLogout } from '../../utils/user-utils'

const SessionExpiredDialog: FC = (): ReactElement => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  useEffect(() => {
    const logoutTimeout = setTimeout(() => {
      onLogout()
    }, 1000 * 10)

    return () => {
      if (logoutTimeout) {
        clearTimeout(logoutTimeout)
      }
    }
  }, [])

  const onLogout = () => {
    userLogout(false)
    navigate(LOGIN_ROUTE)
    dispatch(showSessionExpiredDialog(false))
  }

  return (
    <>
      <Modal
        title={Strings.sessionExpired}
        open={true}
        closable={false}
        cancelButtonProps={{ style: { display: 'none' } }}
        onOk={onLogout}
        okText={Strings.logoutImmediately}>
        <p>{Strings.sessionHasExpiredMessage}</p>
      </Modal>
    </>
  )
}

export default SessionExpiredDialog
