import ProtectedRoute from '../users/components/protected-route/protected-route'
import Dashboard from './pages/dashboard/dashboard'

const routes = [
  {
    path: '/*',
    element: (
      <ProtectedRoute>
        <Dashboard />
      </ProtectedRoute>
    )
  }
]

export default routes
