import { RcFile } from 'antd/es/upload'
import { AxiosError } from 'axios'
import moment from 'moment'
import { SyntheticEvent } from 'react'
import { FILE_UPLOAD_MAX_SIZE, MESSAGE_TYPE_ERROR } from '../common-constants'
import { showToastMessage } from '../components/common/toast-message/toast-message'
import Strings from '../services/localization-service'
import { IErrorResponse } from '../types/IErrorResponse'
import { IPagination } from '../types/IPagination'
import { ITablePagination } from '../types/ITablePagination'

export function getErrorMessage(error: AxiosError<IErrorResponse> | Error) {
  let msg = Strings.commonErrorMessage

  if (error && error instanceof AxiosError) {
    if (error.response && error.response.data && error.response.data.message) {
      msg = error.response.data.message
    }
  } else if (error && error.message) {
    msg = error.message
  }

  return msg
}

export const getTablePagination = (
  pagination: IPagination | null | undefined
) => {
  let currentPage = 1

  if (pagination && pagination.offset !== 0) {
    currentPage = Math.round(pagination.offset / 10) + 1
  }

  return {
    current: currentPage,
    pageSize: pagination?.limit || 10,
    total: (pagination && pagination.count) || 10,
    pageSizeOptions: ['5', '10', '25'],
    showSizeChanger: true
  }
}

export const getOffset = (pagination: ITablePagination) => {
  if (pagination.current && pagination.pageSize) {
    return (pagination.current - 1) * pagination.pageSize
  }

  return 0
}

export const getSortDir = (sortDirection: string) => {
  if (sortDirection === 'ascend') {
    return 1
  } else {
    return -1
  }
}

export const getListQuery = (
  pagination: ITablePagination,
  searchText: string | undefined
) => {
  let query = ''

  if (pagination) {
    query += `?offset=${getOffset(pagination)}&limit=${pagination.pageSize}`

    if (searchText) {
      query += `&search=${searchText}`
    }

    if (pagination.columnKey && pagination.order) {
      query += `&sort-column=${pagination.columnKey}`
      query += `&sort-dir=${getSortDir(pagination.order)}`
    }
  }
  return query
}

export const getFileFromEvent = (event: SyntheticEvent) => {
  const target = event.target as HTMLInputElement
  return target?.files && target.files.length > 0 && target.files[0]
}

export const getPhoneNumberWithCountryCode = (
  phoneNumber: string,
  countryCode: string
) => {
  return `${countryCode}-${phoneNumber}`
}

export const getPhoneNumber = (phoneNumber: string) => {
  return phoneNumber.split('-')[1]
}
export const getCountryCode = (phoneNumber: string) => {
  return phoneNumber.split('-')[0]
}

export const validateEmail = (email: string) => {
  const emailRegex = /\S+@\S+\.\S+/
  return emailRegex.test(email)
}

export const getFormattedDate = (date: string, format = 'DD MMM YYYY') => {
  return date && moment(date).format(format)
}

export const getArrayFromCommaSeparated = (
  commaSeparatedString: string
): string[] => {
  if (!commaSeparatedString) {
    return []
  }
  return commaSeparatedString.split(',')
}

export const getCommaSeparatedString = (arr: string[]) => {
  if (!arr) {
    return ''
  }
  return arr.join(',')
}

export const getValueFromEvent = (event: SyntheticEvent) => {
  const target = event.target as HTMLInputElement
  return target.value
}

export const convertArrayToString = (inputArray: string) => {
  return [inputArray].join(',')
}

export const isPhoneType = (fieldKey: string) => {
  return (
    fieldKey.toLowerCase().includes('phone') ||
    fieldKey.toLowerCase().includes('mobile')
  )
}

export const fileTypeSizeValidation = (file: RcFile, accept: string) => {
  const allowedType = accept.split(',')
  const fileExtension = file.name.split('.').pop() || ''
  const isAllowedType = allowedType.includes(`.${fileExtension}`)
  console.log('fileExtension', fileExtension)

  if (!isAllowedType) {
    showToastMessage(
      MESSAGE_TYPE_ERROR,
      `You can only upload ${allowedType.join('/').toLocaleUpperCase()} file!`
    )
  }
  const isLt10MB = file.size <= FILE_UPLOAD_MAX_SIZE
  if (!isLt10MB) {
    showToastMessage(MESSAGE_TYPE_ERROR, Strings.fileUploadMaxLimitErr)
  }
  return isAllowedType && isLt10MB
}
