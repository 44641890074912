import { lazy } from 'react'
const ForgotPassword = lazy(
  () => import('./pages/forgot-password/forgot-password')
)
const Login = lazy(() => import('./pages/login/login'))
const Register = lazy(() => import('./pages/register/register'))
const ResetPassword = lazy(() => import('./pages/reset-pasword/reset-password'))
export const LOGIN_ROUTE = '/login'
export const REGISTER_ROUTE = '/register'
export const USERS_ROUTE = '/users'
export const NOTIFICATION_ROUTE = '/notification'
export const USER_PROFILE_ROUTE = '/user-profile'
export const CHANGE_PASSWORD_ROUTE = '/change-password'
export const FORGOT_PASSWORD_ROUTE = '/forgot-password'
export const RESET_PASSWORD_ROUTE = '/password-reset'

const routes = [
  {
    path: '/login',
    element: <Login />
  },
  // {
  //   path: '/register',
  //   element: <Register />
  // },
  {
    path: '/forgot-password',
    element: <ForgotPassword />
  },
  {
    path: '/password-reset',
    element: <ResetPassword />
  }
]

export default routes
