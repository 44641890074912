import LocalizedStrings from 'react-localization'
import { UserStrings } from '../../../modules/users/localization/strings'
import { FaqStrings } from '../../../modules/faqs/localization/strings'
import { CommonStrings } from '../../../modules/common/localization/strings'
import { ContactUsStrings } from '../../../modules/contact-us/localization/strings'
import { NotificationStrings } from '../../notification/localization/string'
import { DashboardStrings } from '../../dashboard/localization/strings'
import { EntityStrings } from '../../entities/localization/strings'
import { VersionStrings } from '../../versions/localization/string'
import { NotifyUsersStrings } from '../../notify-users/localization/strings'

const stringsArr = [
  UserStrings,
  CommonStrings,
  FaqStrings,
  ContactUsStrings,
  NotificationStrings,
  DashboardStrings,
  EntityStrings,
  VersionStrings,
  NotifyUsersStrings
]

const messages: { [key: string]: { [key: string]: string } } = {}

for (let i = 0; i < stringsArr.length; i++) {
  Object.keys(stringsArr[i]).forEach((key: string) => {
    const languageObj = stringsArr[i]
    messages[key] = {
      ...messages[key],
      ...languageObj[key as keyof typeof languageObj]
    }
  })
}

const Strings = new LocalizedStrings(messages)

export default Strings
