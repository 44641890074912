import Strings from './services/localization-service'

export const MESSAGE_TYPE_SUCCESS = 'MESSAGE_TYPE_SUCCESS'
export const MESSAGE_TYPE_WARNING = 'MESSAGE_TYPE_WARNING'
export const MESSAGE_TYPE_ERROR = 'MESSAGE_TYPE_ERROR'
export const FILE_UPLOAD_MAX_SIZE = 1024 * 1024 * 10

export enum FieldType {
  TEXT = 'text',
  TEXT_AREA = 'text-area',
  DATE = 'date',
  DROPDOWN = 'dropdown',
  COLOR = 'color',
  IMAGE = 'image',
  FILE = 'file'
}

export const fieldTypeDropdownValues = [
  {
    value: FieldType.TEXT,
    label: Strings.text
  },
  {
    value: FieldType.TEXT_AREA,
    label: Strings.textArea
  },
  {
    value: FieldType.DATE,
    label: Strings.date
  },
  {
    value: FieldType.DROPDOWN,
    label: Strings.dropdown
  },
  {
    value: FieldType.COLOR,
    label: Strings.color
  },
  {
    value: FieldType.IMAGE,
    label: Strings.image
  },
  {
    value: FieldType.FILE,
    label: Strings.file
  }
]

export const imageTypes = [
  {
    value: '.png',
    label: Strings.png
  },
  {
    value: '.jpeg',
    label: Strings.jpeg
  },
  {
    value: '.jpg',
    label: Strings.jpg
  }
]

export const fileTypes = [
  {
    value: '.pdf',
    label: Strings.pdf
  },
  {
    value: '.doc',
    label: Strings.wordDoc
  },
  {
    value: '.docx',
    label: Strings.wordDocx
  },
  {
    value: '.xls',
    label: Strings.excel
  },
  {
    value: '.xlsx',
    label: Strings.excelX
  },
  {
    value: '.html',
    label: Strings.html
  }
]
