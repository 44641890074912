export const VersionStrings = {
  en: {
    version: 'Versions',
    addVersion: 'Add Version',
    addVersionForm: 'Add Version Form',
    active: 'Active',
    internalVersion: 'Internal Version',
    releaseNote: 'Release Note',
    mandatory: 'Mandatory',
    prerelease: 'Pre-release',
    supportEndsOn: 'Support Ends On',
    releaseDate: 'Release Date',
    appType: 'App Type',
    selectAppType: 'Select App Type',
    user: 'user',
    all: 'All',
    true: 'true',
    false: 'false',
    deviceType: 'Device Type',
    selectDeviceType: 'Select Device Type',
    android: 'android',
    iOS: 'iOS',
    appLink: 'App Link',
    versionsAddSuccess: 'Versions Added Successfully',
    versionsUpdateSuccess: 'Versions Updated Successfully',
    editVersion: 'Edit Version',
    deleteConfirmationDialogText: 'Are you sure  you want to delete this ?'
  }
}
