import { IRoleAccess } from '../types/IRoleAccess'
import { IUser } from '../types/IUser'
import { AUTH, OPEN_ROUTE } from './roles-constants'

const replaceUuidsWithStr = (path: string) => {
  return path.replace(
    /[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}/gi,
    ':id'
  )
}

export const validateRoute = (
  roleAccesses: IRoleAccess[],
  userProfile: IUser
) => {
  const pathName = replaceUuidsWithStr(location.pathname)

  const pathObj = roleAccesses.find(
    (roleAccess) => roleAccess.path === pathName
  )

  if (pathName === '/') {
    return true
  }

  let allowed = false

  if (pathObj && userProfile) {
    allowed = validateRoles(userProfile, pathObj.roles)

    if (pathObj.roles.includes(OPEN_ROUTE)) {
      allowed = true
    }

    if (pathObj.roles.includes(AUTH)) {
      allowed = true
    }
  }

  return allowed
}

const validateRoles = (userProfile: IUser, roles: string[]) => {
  return userProfile.roles.some((roleObj) => {
    if (roles.includes(roleObj.role)) {
      return true
    } else {
      return false
    }
  })
}

export const validateSideMenus = (
  userProfile: IUser,
  allowedRoles: string[]
) => {
  if (allowedRoles.includes(AUTH) || allowedRoles.includes(OPEN_ROUTE)) {
    return true
  }

  return validateRoles(userProfile, allowedRoles)
}
