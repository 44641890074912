import { Avatar, Badge, Dropdown, Layout, Space } from 'antd'
import {
  UserOutlined,
  DownOutlined,
  BellOutlined,
  MenuUnfoldOutlined
} from '@ant-design/icons'
import './header.scss'
import type { MenuProps } from 'antd'
const { Header } = Layout
import { useNavigate } from 'react-router-dom'
import React, { FC, useState } from 'react'
import { IUser } from '../../../users/types/IUser'
import Strings from '../../../common/services/localization-service'
import { LOGIN_ROUTE, USER_PROFILE_ROUTE } from '../../../users/routes'
import Notification from '../../../notification/pages/notifications/notifications'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../redux-store'
import AppConfirmationDialog from '../../../common/components/common/app-confirmation-dialog/app-confirmation-dialog'
import { setSideBarCollapsed } from '../../slices/sidebar-slice'
import { userLogout } from '../../../users/utils/user-utils'

interface ITopHeaderProps {
  userProfile: IUser | null
}

const TopHeader: FC<ITopHeaderProps> = ({ userProfile }: ITopHeaderProps) => {
  const [open, setOpen] = useState(false)
  const [showDialog, setShowDialog] = useState(false)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const notification = useSelector(
    (state: RootState) => state.notification.notification
  )

  const sideMenuCollapsed = useSelector(
    (state: RootState) => state.sidebar.sideBarCollapsed
  )

  const items: MenuProps['items'] = [
    {
      label: 'Profile',
      onClick: () => {
        navigate(USER_PROFILE_ROUTE)
      },
      key: '0'
    },
    {
      label: 'Logout',
      onClick: () => {
        setShowDialog(true)
      },
      key: '1',
      danger: true
    }
  ]

  return (
    <>
      <Header>
        <div className='header-layout'>
          <div className='logo-container'>
            <div style={{ marginTop: 10 }}>
              {React.createElement(
                sideMenuCollapsed ? MenuUnfoldOutlined : MenuUnfoldOutlined,
                {
                  className: 'trigger',
                  onClick: () =>
                    dispatch(setSideBarCollapsed(!sideMenuCollapsed))
                }
              )}
            </div>
            <img
              className='logo'
              src={require('../../../common/assets/images/app-logo.png')}></img>
            <span className='app-name'>{Strings.appName}</span>
          </div>
          <div>
            {/* <Badge
              className='bell-outlined'
              count={notification}
              offset={[5, 1]}>
              <BellOutlined
                className='bell-outlinedIcon'
                onClick={() => setOpen(true)}
              />
            </Badge> */}
            {userProfile?.profilePicUrl ? (
              <Avatar
                className='avatar'
                size={36}
                src={userProfile?.profilePicUrl}
              />
            ) : (
              <Avatar
                className='avatar'
                size={36}
                icon={<UserOutlined />}
              />
            )}
            <Dropdown
              menu={{ items }}
              trigger={['hover']}>
              <a onClick={(e) => e.preventDefault()}>
                <Space>
                  <span style={{ fontSize: 14 }}>
                    {userProfile
                      ? `${userProfile?.firstName} ${userProfile?.lastName}`
                      : ''}
                  </span>
                  <DownOutlined />
                </Space>
              </a>
            </Dropdown>
          </div>
          {showDialog && (
            <AppConfirmationDialog
              handleCancel={() => setShowDialog(false)}
              handleOk={() => {
                userLogout(true)
                navigate(LOGIN_ROUTE)
              }}
              description={Strings.description}
              title={Strings.logout}
            />
          )}
        </div>
      </Header>
      {open && (
        <Notification
          setOpen={setOpen}
          open={open}
        />
      )}
    </>
  )
}

export default TopHeader
