import axios, { Axios } from 'axios'
import config from '../../../config'
import { store } from '../../../redux-store'
import { usersSlice } from '../../users/slices/user-slice'
import {
  getSessionRefreshToken,
  getSessionToken,
  saveSessionToken
} from './local-storage-service'

export const getApiClient = (): Axios => {
  const client = axios.create({
    baseURL: config.app.baseUrl
  })
  return client
}

export const getApiClientWithAuth = (): Axios => {
  const client = axios.create({
    baseURL: config.app.baseUrl,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getSessionToken()}`
    }
  })

  client.interceptors.response.use(
    (response) => {
      return response
    },
    async (error) => {
      const config = error.config
      if (error.response && error.response.status === 401 && !config._retry) {
        config._retry = true
        try {
          const prevRequest = error?.config

          const res = await getRefreshToken()

          if (res.authToken) {
            saveSessionToken(res.authToken)
          }
          return await client({
            ...prevRequest,
            headers: {
              ...prevRequest.headers,
              Authorization: `Bearer ${res.authToken}`
            },
            sent: true
          })
        } catch (err) {
          store.dispatch(usersSlice.actions.showSessionExpiredDialog(true))
        }
      }
      return Promise.reject(error)
    }
  )

  return client
}

export const getRefreshToken = async () => {
  try {
    const refreshToken = getSessionRefreshToken()
    const response = await getApiClient().post(
      '/api/v1/sessions/verify-refresh-token',
      { refreshToken }
    )
    return response.data
  } catch (error) {
    throw error
  }
}
