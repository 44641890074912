import { configureStore } from '@reduxjs/toolkit'
import userReducer from './modules/users/slices/user-slice'
import loaderReducer from './modules/common/slices/loader-slice'
import notificationSlice from './modules/notification/slices/notification-slice'
import sidebarReducer from './modules/dashboard/slices/sidebar-slice'

export const store = configureStore({
  reducer: {
    users: userReducer,
    loader: loaderReducer,
    notification: notificationSlice,
    sidebar: sidebarReducer
  }
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
