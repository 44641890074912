import React, { lazy, Suspense, useEffect } from 'react'
import { Affix, Layout } from 'antd'
import SideMenu from '../../components/side-menu/side-menu'
import TopHeader from '../../components/header/header'
import './dashboard.scss'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../redux-store'
import { Route, Routes } from 'react-router-dom'
import {
  USERS_ROUTE,
  USER_PROFILE_ROUTE,
  CHANGE_PASSWORD_ROUTE
} from '../../../users/routes'

import {
  FAQ_ADD_ROUTE,
  FAQ_EDIT_ROUTE,
  FAQ_ROUTE,
  MANAGE_CATEGORY_ROUTE
} from '../../../faqs/routes'

import {
  ADD_CONTACT_US_FORM_ROUTE,
  CONTACT_US_FORM_SUBMISSION_ROUTE,
  CONTACT_US_FORMS_ROUTE,
  CONTACT_US_MESSAGES_ROUTE,
  CONTACT_US_SPAM_MESSAGES_ROUTE,
  CONTACT_US_DETAILS_ROUTE
} from '../../../contact-us/routes'
import {
  ENTITIES_ROUTE,
  ENTITY_MESSAGES_ROUTE,
  ENTITY_SUBMISSION_ROUTE,
  ENTITY_SUBMISSION_EDIT_ROUTE,
  ADD_ENTITY_ROUTE
} from '../../../entities/routes'
import { setNotification } from '../../../notification/slices/notification-slice'
import { getNotificationUnreadCount } from '../../../notification/services/notification-service'
import { showToastMessage } from '../../../common/components/common/toast-message/toast-message'
import { MESSAGE_TYPE_ERROR } from '../../../common/common-constants'
import { getErrorMessage } from '../../../common/utils/common-utils'
import SessionExpiredDialog from '../../../users/components/session-expired-dialog/session-expired-dialog'

const UserProfile = lazy(
  () => import('../../../users/pages/user-profile/user-profile')
)
const Users = lazy(() => import('../../../users/pages/users/users'))
const FaqList = lazy(() => import('../../../faqs/pages/faq-list/faq-list'))
const ContactUsFormSubmission = lazy(
  () =>
    import(
      '../../../contact-us/pages/contact-us-form-submission/contact-us-form-submission'
    )
)
const ContactUsMessages = lazy(
  () =>
    import('../../../contact-us/pages/contact-us-messages/contact-us-messages')
)
const FaqAdd = lazy(() => import('../../../faqs/pages/faq-add/faq-add'))
const ChangePassword = lazy(
  () => import('../../../users/pages/change-password/change-password')
)
const PageNotFound = lazy(
  () => import('../../../common/pages/page-not-found/page-not-found')
)
const MainDashboard = lazy(() => import('../main-dashboard/main-dashboard'))
const FaqEdit = lazy(() => import('../../../faqs/pages/faq-edit/faq-edit'))
const ManageCategory = lazy(
  () => import('../../../faqs/pages/manage-category/manage-category')
)
const ContactMessageDetails = lazy(
  () =>
    import(
      '../../../contact-us/pages/contact-message-details/contact-message-details'
    )
)
const ContactUsForms = lazy(
  () => import('../../../contact-us/pages/contact-us-forms/contact-us-forms')
)
const AddContactForm = lazy(
  () => import('../../../contact-us/pages/add-contact-form/add-contact-form')
)
const EntityMessages = lazy(
  () => import('../../../entities/pages/entity-messages/entity-messages')
)
const Entities = lazy(() => import('../../../entities/pages/entities/entities'))
const AddEntity = lazy(
  () => import('../../../entities/pages/add-entity/add-entity')
)
const EntitySubmission = lazy(
  () => import('../../../entities/pages/entity-submission/entity-submission')
)
const Versions = lazy(() => import('../../../versions/pages/versions/versions'))
const VersionAdd = lazy(
  () => import('../../../versions/pages/add-version/add-version')
)
const VersionEdit = lazy(
  () => import('../../../versions/pages/edit-version/edit-version')
)

import { setLoader } from '../../../common/slices/loader-slice'
import {
  VERSION_ADD_ROUTE,
  VERSION_EDIT_ROUTE,
  VERSION_ROUTE
} from '../../../versions/routes'
import AppLoader from '../../../common/components/common/app-loader/app-loader'

import {
  NOTIFY_USERS_ADD_ROUTE,
  NOTIFY_USERS_ROUTE
} from '../../../notify-users/routes'
import NotifyUsersAdd from '../../../notify-users/pages/add-notify-users/add-notify-users'

import NotifyUsers from '../../../notify-users/pages/notify-users/notify-users'

const { Content } = Layout

const Dashboard: React.FC = () => {
  const userProfile = useSelector((state: RootState) => state.users.userProfile)
  const showSessionExpiredDialog = useSelector(
    (state: RootState) => state.users.showSessionExpiredDialog
  )
  const dispatch = useDispatch()

  const callNotificationUnreadCountApi = async () => {
    dispatch(setLoader(true))
    try {
      const notificationResponse = await getNotificationUnreadCount()
      dispatch(setNotification(notificationResponse?.count || 0))
    } catch (err) {
      showToastMessage(MESSAGE_TYPE_ERROR, getErrorMessage(err as Error))
    } finally {
      dispatch(setLoader(false))
    }
  }

  useEffect(() => {
    // callNotificationUnreadCountApi()
  }, [])

  return (
    <Layout className='main-layout'>
      <Affix offsetTop={0}>
        <TopHeader userProfile={userProfile} />
      </Affix>
      <Layout>
        <Affix className='sidebar-layout'>
          <SideMenu></SideMenu>
        </Affix>
        <Layout className='content-layout'>
          <Content className='content-container'>
            <div className='content'>
              <div className='common-background'>
                {showSessionExpiredDialog && (
                  <SessionExpiredDialog></SessionExpiredDialog>
                )}
                <Suspense fallback={<AppLoader></AppLoader>}>
                  <Routes>
                    <Route
                      path='/'
                      element={<MainDashboard />}
                    />
                    <Route
                      path={USERS_ROUTE}
                      element={<Users />}
                    />
                    <Route
                      path={USER_PROFILE_ROUTE}
                      element={userProfile && <UserProfile />}
                    />
                    <Route
                      path={FAQ_ROUTE}
                      element={<FaqList />}
                    />
                    <Route
                      path={FAQ_ADD_ROUTE}
                      element={<FaqAdd />}
                    />
                    <Route
                      path={FAQ_EDIT_ROUTE}
                      element={<FaqEdit />}
                    />
                    <Route
                      path={MANAGE_CATEGORY_ROUTE}
                      element={<ManageCategory />}
                    />
                    <Route
                      path={CHANGE_PASSWORD_ROUTE}
                      element={userProfile && <ChangePassword />}
                    />
                    <Route
                      path={CONTACT_US_FORMS_ROUTE}
                      element={<ContactUsForms />}
                    />
                    <Route
                      path={ADD_CONTACT_US_FORM_ROUTE}
                      element={<AddContactForm />}
                    />
                    <Route
                      path={CONTACT_US_FORM_SUBMISSION_ROUTE}
                      element={<ContactUsFormSubmission />}
                    />
                    <Route
                      path={CONTACT_US_MESSAGES_ROUTE}
                      element={<ContactUsMessages spamDetected={false} />}
                    />
                    <Route
                      path={CONTACT_US_SPAM_MESSAGES_ROUTE}
                      element={<ContactUsMessages spamDetected={true} />}
                    />
                    <Route
                      path={CONTACT_US_DETAILS_ROUTE}
                      element={<ContactMessageDetails />}
                    />
                    {/* <Route
                      path={ENTITIES_ROUTE}
                      element={<Entities />}
                    />
                    <Route
                      path={ENTITY_MESSAGES_ROUTE}
                      element={<EntityMessages />}
                    />
                    <Route
                      path={ADD_ENTITY_ROUTE}
                      element={<AddEntity />}
                    />
                    <Route
                      path={ENTITY_SUBMISSION_ROUTE}
                      element={<EntitySubmission type='add' />}
                    />
                    <Route
                      path={ENTITY_SUBMISSION_EDIT_ROUTE}
                      element={<EntitySubmission type='edit' />}
                    />
                    <Route
                      path={VERSION_ROUTE}
                      element={<Versions />}
                    />
                    <Route
                      path={VERSION_ADD_ROUTE}
                      element={<VersionAdd />}
                    />
                    <Route
                      path={VERSION_EDIT_ROUTE}
                      element={<VersionEdit />}
                    />
                    <Route
                      path={NOTIFY_USERS_ROUTE}
                      element={<NotifyUsers />}
                    />
                    <Route
                      path={NOTIFY_USERS_ADD_ROUTE}
                      element={<NotifyUsersAdd />}
                    /> */}
                    <Route
                      path='*'
                      element={<PageNotFound />}
                    />
                  </Routes>
                </Suspense>
              </div>
            </div>
          </Content>
        </Layout>
      </Layout>
    </Layout>
  )
}

export default Dashboard
