import { ReactElement, useEffect, useState } from 'react'
import { Navigate, useNavigate } from 'react-router-dom'
import { getSessionToken } from '../../../common/services/local-storage-service'
import { LOGIN_ROUTE } from '../../routes'
import { useDispatch, useSelector } from 'react-redux'
import { getRoleAccesses, getUserProfile } from '../../services/user-service'
import { setRolesAccesses, setUserProfile } from '../../slices/user-slice'
import { RootState } from '../../../../redux-store'
import { validateRoute } from '../../utils/roles-utils'
import AppLoader from '../../../common/components/common/app-loader/app-loader'

interface IProtectedRouteProps {
  children: ReactElement
}

const ProtectedRoute = ({ children }: IProtectedRouteProps) => {
  const authToken = getSessionToken()
  const { userProfile, rolesAccesses } = useSelector(
    (state: RootState) => state.users
  )
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [loader, setLoader] = useState(false)

  useEffect(() => {
    if (authToken) {
      fetchUserProfile()
    }
  }, [])

  const fetchUserProfile = async () => {
    setLoader(true)
    try {
      const user = await getUserProfile()
      await fetchRolesAccesses()
      if (user) {
        dispatch(setUserProfile(user))
      }
    } catch (e) {
      navigate(LOGIN_ROUTE)
    } finally {
      setLoader(false)
    }
  }

  const fetchRolesAccesses = async () => {
    const rolesAccesses = await getRoleAccesses()
    if (rolesAccesses) {
      dispatch(setRolesAccesses(rolesAccesses))
    }
  }

  if (!authToken) {
    return <Navigate to={LOGIN_ROUTE}></Navigate>
  }

  if (loader) {
    return <AppLoader />
  }

  if (
    userProfile &&
    rolesAccesses &&
    !validateRoute(rolesAccesses, userProfile)
  ) {
    return <Navigate to={'/'}></Navigate>
  }

  return children
}

export default ProtectedRoute
