import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

export interface INotificationState {
  notification: number
}

const initialState: INotificationState = {
  notification: 0
}

export const notificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    setNotification: (state, action: PayloadAction<number>) => {
      state.notification = action.payload
    }
  }
})

export const { setNotification } = notificationSlice.actions

export default notificationSlice.reducer
