import { FC, ReactElement } from 'react'
import { Modal } from 'antd'

interface IAppConfirmationDialog {
  title?: string
  description: string
  handleCancel: () => void
  handleOk: () => void
  closable?: boolean
  maskClosable?: boolean
}

const AppConfirmationDialog: FC<IAppConfirmationDialog> = ({
  title = '',
  description,
  handleCancel,
  handleOk,
  closable = true,
  maskClosable = true
}): ReactElement => {
  return (
    <>
      <Modal
        title={title}
        open={true}
        onOk={handleOk}
        onCancel={handleCancel}
        closable={closable}
        maskClosable={maskClosable}>
        {description}
      </Modal>
    </>
  )
}

export default AppConfirmationDialog
