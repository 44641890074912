import 'antd/dist/reset.css'
import './App.scss'
import { createHashRouter, RouterProvider } from 'react-router-dom'
import UserRoutes from './modules/users/routes'
import DashboardRoutes from './modules/dashboard/routes'
import AppLoader from './modules/common/components/common/app-loader/app-loader'
import { useSelector } from 'react-redux'
import { RootState } from './redux-store'

const router = createHashRouter([...UserRoutes, ...DashboardRoutes])

function App() {
  const loading = useSelector((state: RootState) => state.loader.loader)
  return (
    <div>
      {loading && <AppLoader />}
      <RouterProvider router={router} />
    </div>
  )
}

export default App
