import {
  MESSAGE_TYPE_ERROR,
  MESSAGE_TYPE_SUCCESS
} from '../../common/common-constants'
import { showToastMessage } from '../../common/components/common/toast-message/toast-message'
import Strings from '../../common/services/localization-service'
import {
  clearStorage,
  saveSessionRefreshToken,
  saveSessionToken
} from '../../common/services/local-storage-service'
import { getErrorMessage } from '../../common/utils/common-utils'
import { logout } from '../services/user-service'

export const userLogout = async (shouldCallLogoutApi: boolean) => {
  try {
    if (shouldCallLogoutApi) {
      const logoutResp = await logout()
      if (logoutResp) {
        clearStorage()
        saveSessionToken('')
        saveSessionRefreshToken('')
        showToastMessage(MESSAGE_TYPE_SUCCESS, logoutResp.message)
      }
    } else {
      clearStorage()
      saveSessionToken('')
      saveSessionRefreshToken('')
    }
  } catch (err) {
    showToastMessage(MESSAGE_TYPE_ERROR, getErrorMessage(err as Error))
  }
}

export const getPasswordValidator = (customRequiredMsg: string) => {
  return [
    {
      required: true,
      message: customRequiredMsg
    },
    {
      pattern: new RegExp('(?=.*?[0-9])(?=.*?[A-Za-z])(?=.*[^0-9A-Za-z]).+$'),
      message: Strings.passwordRegError
    },
    { min: 8, message: Strings.passwordMinLengthError },
    {
      max: 30,
      message: Strings.passwordMaxLengthError
    }
  ]
}
