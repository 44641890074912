import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

export interface ISideBar {
  sideBarCollapsed: boolean
}

const initialState: ISideBar = {
  sideBarCollapsed: false
}

export const sidebarReducer = createSlice({
  name: 'sideBar',
  initialState,
  reducers: {
    setSideBarCollapsed: (state, action: PayloadAction<boolean>) => {
      state.sideBarCollapsed = action.payload
    }
  }
})

export const { setSideBarCollapsed } = sidebarReducer.actions

export default sidebarReducer.reducer
