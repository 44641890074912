import { FC, useEffect, useState } from 'react'
import './notifications.scss'
import { Col, Drawer, Row } from 'antd'
import { INotification } from '../../types/INotificationResponse'
import { showToastMessage } from '../../../common/components/common/toast-message/toast-message'
import { MESSAGE_TYPE_ERROR } from '../../../common/common-constants'
import { getErrorMessage } from '../../../common/utils/common-utils'
import moment from 'moment'
import { CloseOutlined } from '@ant-design/icons'
import {
  getNotifications,
  notificationsMarkAsRead
} from '../../services/notification-service'
import Strings from '../../../common/services/localization-service'
import { useDispatch } from 'react-redux'
import { setNotification } from '../../slices/notification-slice'
import { setLoader } from '../../../common/slices/loader-slice'

interface INotificationProps {
  setOpen: (value: boolean) => void
  open: boolean
}

const Notification: FC<INotificationProps> = ({
  setOpen,
  open
}: INotificationProps) => {
  const [notifications, setNotifications] = useState<INotification[] | null>()
  const dispatch = useDispatch()

  const handleCancel = async () => {
    setOpen(false)
    dispatch(setLoader(true))
    try {
      if (notifications && notifications.length > 0) {
        const notificationIdListArray: string[] = notifications
          .filter((val: INotification) => val.readAt === 0)
          .map((val) => val.notificationId)

        if (notificationIdListArray && notificationIdListArray.length > 0) {
          const response = await notificationsMarkAsRead({
            notificationIdList: notificationIdListArray
          })

          if (response) {
            setOpen(false)
            dispatch(setNotification(0))
          }
        }
      }
    } catch (err) {
      showToastMessage(MESSAGE_TYPE_ERROR, getErrorMessage(err as Error))
    } finally {
      dispatch(setLoader(false))
    }
  }

  const callNotificationsApi = async () => {
    dispatch(setLoader(true))
    try {
      const query = '?offset=0&limit=100'
      const notificationResponse = await getNotifications(query)
      setNotifications(notificationResponse?.results)
    } catch (err) {
      showToastMessage(MESSAGE_TYPE_ERROR, getErrorMessage(err as Error))
    } finally {
      dispatch(setLoader(false))
    }
  }

  useEffect(() => {
    callNotificationsApi()
  }, [])

  const formatDateTime = (dateTime: string) => {
    return dateTime ? moment(dateTime).calendar() : ''
  }

  return (
    <>
      <Drawer
        title={
          <div className='notification-header-container'>
            <Row className='notification-header'>
              <Col>
                <div className='notification-text'>{Strings.notification}</div>
              </Col>
              <Col>
                <div className='image-container'>
                  <CloseOutlined
                    className='close-icon-size'
                    alt='close-icon'
                    onClick={handleCancel}
                  />
                </div>
              </Col>
            </Row>
          </div>
        }
        placement='right'
        closable={false}
        onClose={handleCancel}
        open={open}
        width={'30vw'}>
        {Array.isArray(notifications) &&
          notifications.map((notification: INotification) => (
            <div
              className={
                notification.readAt > 0
                  ? 'notification-card white-bg'
                  : 'notification-card gray-bg'
              }
              key={notification.createdAt}>
              <div key={notification.createdAt}>
                <span className='notification-msg'>{notification.msg}</span>
                <div>
                  <small>{formatDateTime(notification.createdAt)}</small>
                </div>
              </div>
            </div>
          ))}
      </Drawer>
    </>
  )
}

export default Notification
