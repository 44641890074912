import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { IUser } from '../types/IUser'
import { IRoleAccess } from '../types/IRoleAccess'

export interface IUserState {
  userProfile: IUser | null
  rolesAccesses: IRoleAccess[]
  showSessionExpiredDialog: boolean
}

const initialState: IUserState = {
  userProfile: null,
  rolesAccesses: [],
  showSessionExpiredDialog: false
}

export const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    setUserProfile: (state, action: PayloadAction<IUser>) => {
      state.userProfile = action.payload
    },
    setRolesAccesses: (state, action: PayloadAction<IRoleAccess[]>) => {
      state.rolesAccesses = action.payload
    },
    showSessionExpiredDialog: (state, action: PayloadAction<boolean>) => {
      state.showSessionExpiredDialog = action.payload
    }
  }
})

export const { setUserProfile, setRolesAccesses, showSessionExpiredDialog } =
  usersSlice.actions

export default usersSlice.reducer
