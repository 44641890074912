export const FaqStrings = {
  en: {
    faqList: 'FAQ List',
    addFaq: 'Add FAQ',
    question: 'Question',
    answer: 'Answer',
    fieldRequired: 'This field is required',
    add: 'Add',
    selectCategory: 'Select Category',
    addCategory: 'Add Category',
    cancel: 'Cancel',
    categoryName: 'Category Name',
    categoryAddSuccess: 'New Category Added Successfully',
    faqAddSuccess: 'FAQ Added Successfully',
    manageCategory: 'Manage Categories',
    deleteSuccessMsg: 'FAQ  deleted successfully',
    editFaq: 'Edit FAQ',
    update: 'Update',
    delCategorySuccessMsg: 'Category deleted successfully',
    maxLimitError: 'must not exceed 255 characters'
  }
}
