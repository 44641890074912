export const EntityStrings = {
  en: {
    addEntity: 'Add Entity',
    editEntity: 'Edit Entity',
    selectLanguage: 'Select Language',
    language: 'Language',
    languageError: 'Default language "-" entry is required',
    save: 'Save',
    fileType: 'File Storage Type',
    public: 'Public',
    private: 'Private',
    entityAddSuccess: 'Entity added successfully.',
    entityUpdateSuccess: 'Entity updated successfully.',
    checkSpamMessage: 'Check Spam Message',
    saveAndPublish: 'Save and Publish',
    entitySubmissionSuccess: 'Entity Submission data saved successfully',
    entity: 'Entity',
    status: 'Status'
  }
}
