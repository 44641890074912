import { getApiClientWithAuth } from '../../common/services/api-service'
import { ISuccessResponse } from '../../common/types/ISuccessResponse'
import { INotificationMarkAsReadRequest } from '../types/INotificationMarkAsReadResponse'
import { INotificationResponse } from '../types/INotificationResponse'
import { INotificationUnreadCountResponse } from '../types/INotificationUnreadCountResponse'

export const getNotifications = async (
  params: string
): Promise<INotificationResponse> => {
  try {
    const response = await getApiClientWithAuth().get(
      `/api/v1/notifications${params}`
    )
    return response.data
  } catch (error) {
    throw error
  }
}

export const getNotificationUnreadCount =
  async (): Promise<INotificationUnreadCountResponse> => {
    try {
      const response = await getApiClientWithAuth().get(
        '/api/v1/notifications/unread-count'
      )
      return response.data
    } catch (error) {
      throw error
    }
  }

export const notificationsMarkAsRead = async (
  notificationReq: INotificationMarkAsReadRequest
): Promise<ISuccessResponse> => {
  try {
    const response = await getApiClientWithAuth().put(
      '/api/v1/notifications/mark-as-read',
      notificationReq
    )
    return response.data
  } catch (error) {
    throw error
  }
}
