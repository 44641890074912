import { FC } from 'react'
import { Space, Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import './app-loader.scss'

const antIcon = (
  <LoadingOutlined
    style={{ fontSize: 24 }}
    spin
  />
)

const AppLoader: FC = () => (
  <Space className='loader-main-container'>
    <Spin
      tip='Loading'
      indicator={antIcon}
      size='small'></Spin>
  </Space>
)

export default AppLoader
