import {
  getApiClient,
  getApiClientWithAuth
} from '../../common/services/api-service'
import { IListResponse } from '../../common/types/IListResponse'
import { ILoginRequest } from '../types/ILoginRequest'
import { ILoginResponse } from '../types/ILoginResponse'
import { IRegisterResponse } from '../types/IRegisterResponse'
import { IRegisterRequest } from '../types/IRegisterRequest'
import { IUser } from '../types/IUser'
import { IProfileRequest } from '../types/IProfileRequest'
import { IForgotPasswordRequest } from '../types/IForgotPasswordRequest'
import { IResetPasswordRequest } from '../types/IResetPasswordRequest'
import { IChangePasswordRequest } from '../types/IChangePasswordRequest'
import { ISuccessResponse } from '../../common/types/ISuccessResponse'
import { IRoleAccess } from '../types/IRoleAccess'
import { getUserId } from '../../common/services/local-storage-service'

export const login = async (
  loginRequest: ILoginRequest
): Promise<ILoginResponse | null> => {
  try {
    const response = await getApiClient().post('/api/v1/sessions', loginRequest)
    return response.data
  } catch (error) {
    throw error
  }
}

export const register = async (
  registerRequest: IRegisterRequest
): Promise<IRegisterResponse | null> => {
  try {
    const response = await getApiClient().post(
      '/api/v1/users/register',
      registerRequest
    )
    return response.data
  } catch (error) {
    throw error
  }
}

export const resetPassword = async (
  resetPasswordRequest: IResetPasswordRequest
): Promise<ISuccessResponse | null> => {
  try {
    const response = await getApiClient().post(
      '/api/v1/users/reset-password',
      resetPasswordRequest
    )
    return response.data
  } catch (error) {
    throw error
  }
}

export const forgotPassword = async (
  forgotPasswordRequest: IForgotPasswordRequest
): Promise<ISuccessResponse | null> => {
  try {
    const response = await getApiClient().post(
      '/api/v1/users/forgot-password',
      forgotPasswordRequest
    )
    return response.data
  } catch (error) {
    throw error
  }
}

export const changePassword = async (
  changePasswordRequest: IChangePasswordRequest
): Promise<ISuccessResponse | null> => {
  try {
    const response = await getApiClientWithAuth().post(
      '/api/v1/users/change-password',
      changePasswordRequest
    )
    return response.data
  } catch (error) {
    throw error
  }
}

export const getUserProfile = async (): Promise<IUser | null> => {
  try {
    const response = await getApiClientWithAuth().get(
      `/api/v1/users/${getUserId()}`
    )
    return response.data
  } catch (error) {
    throw error
  }
}

export const getUsers = async (
  params: string
): Promise<IListResponse<IUser> | null> => {
  try {
    const response = await getApiClientWithAuth().get(`/api/v1/users${params}`)
    return response.data
  } catch (error) {
    throw error
  }
}

export const updateUserProfile = async (
  userId: string,
  userProfile: IProfileRequest
): Promise<IUser> => {
  try {
    const response = await getApiClientWithAuth().put(
      `/api/v1/users/${userId}`,
      userProfile
    )
    return response.data
  } catch (error) {
    throw error
  }
}

export const getRoleAccesses = async (): Promise<IRoleAccess[]> => {
  try {
    const response = await getApiClientWithAuth().get(
      `/api/v1/frontend-roles-accesses`
    )
    return response.data
  } catch (error) {
    throw error
  }
}

export const logout = async (): Promise<ISuccessResponse | null> => {
  try {
    const response = await getApiClientWithAuth().delete('/api/v1/sessions')
    return response.data
  } catch (error) {
    throw error
  }
}
