export const ContactUsStrings = {
  en: {
    showCaptcha: 'Show Captcha',
    fieldKey: 'Field Key',
    fieldLabel: 'Field Label',
    fieldType: 'Field Type',
    required: 'Required',
    errorMessage: 'Error Message',
    visibility: 'Visibility',
    action: 'Action',
    tableHeader: 'Form Fields',
    addField: 'Add Field',
    addFormField: 'Add Form Field',
    editFormField: 'Edit Form Field',
    update: 'Update',
    input: 'Input is required! ',
    submit: 'Submit',
    contactUsFormSuccess: 'Contact us form updated successfully.',
    contactUsMessages: 'Contact Us Messages',
    contactUsMessageDelete: 'Contact us message has been deleted successfully',
    contactUsSpamMessages: 'Contact Us Spam Messages',
    fieldExistError: 'Field Key Already Exist',
    saveBlockedIp: 'Save Blocked Ip Address Contact Form',
    contactUsForm: 'Contact Us Form',
    contactFormSuccess: 'Contact Form data added successfully',
    captchaInputPlaceholder: 'Please enter the letters from above image',
    captchaInputLabel: 'Captcha',
    requiredError: 'This field is required',
    formName: 'Form Name',
    addContactUsForm: 'Add Contact Us Form',
    editContactUsForm: 'Edit Contact Us Form',
    text: 'Text',
    textArea: 'Text Area',
    date: 'Date',
    dropdown: 'Dropdown',
    color: 'Color',
    file: 'File',
    image: 'Image',
    choices: 'Choices',
    addChoice: 'Add Choice',
    choiceLabel: 'Choice Label',
    choiceValue: 'Choice Value',
    allowMultipleSelect: 'Allow Multiple Selection',
    enterMultipleEmails: 'Enter multiple emails here',
    contactEmails: 'Contact Emails',
    addEmail: 'Add Email',
    enterEmailHere: 'Enter email here',
    contactUsMessageDetail: 'Contact Us Message Detail',
    isRequired: 'is required',
    uploadImage: 'Upload Image',
    fomFieldError: 'At least 1 form field entry is required',
    choiceIndex: 'Choice Index',
    fomFieldMandatoryError:
      'There should be at least one required field on the form.',
    postedOn: 'Posted On',
    generateInCRM: 'Generate lead in CRM'
  }
}
