export const UserStrings = {
  en: {
    enterFirstName: 'Please input your firstName!',
    enterLastName: 'Please input your lastName!',
    firstName: 'First Name',
    lastName: 'Last Name',
    enterEmail: 'Please input your email!',
    submit: 'Submit',
    email: 'Email',
    newPassword: 'New Password',
    oldPassword: 'Old Password',
    newPasswordChangeSuccessfully: 'Password changed successfully',
    enterPhoneNumber: 'Please input your email!',
    resetPasswordChangeSuccessfully:
      'Your password has been reset successfully !',
    phoneNumber: 'Phone Number',
    or: 'Or',
    phoneNum: 'PhoneNumber',
    confirmPassword: 'Confirm Password',
    enterPassword: 'Please input your password!',
    newPasswordConfirm: 'Please confirm your password!',
    newPasswordConfirmErr: 'The two passwords that you entered do not match!',
    enterNewPassword: 'Please input  password!',
    changePassword: 'Change Password',
    password: 'Password',
    loginSuccessfully: 'Logged in successfully',
    register: 'Register Now!',
    registerSuccessfully: 'Registration  successfully',
    editProfile: 'Edit Profile',
    forgotPassword: 'Password reset link has been sent on your email.',
    forgotPass: 'Forgot password?',
    userProfileSuccess: 'Profile updated successfully',
    fileUploadMaxLimitErr: 'Maximum allowed size 10MB',
    fileUploadSuccess: 'File uploaded successfully',
    name: 'Full Name',
    sessionExpired: 'Session Expired',
    logoutImmediately: 'Logout Immediately',
    sessionHasExpiredMessage:
      'Your Session Has expired, you will be redirected to login after after 10 seconds',
    passwordRegError:
      'Password must contain at least one letter and one number and a special character',
    passwordMinLengthError: 'Password must have a minimum length of 8',
    passwordMaxLengthError: 'Password should be less than 30 characters'
  }
  /*
   * We can add multiple languages like this
   * fr: {
   *  enterEmail: 'Veuillez saisir votre e-mail !',
   *  email: 'e-mail'
   * } */
}
