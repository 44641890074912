export const NotifyUsersStrings = {
  en: {
    notifyUsers: 'Notify Users',
    addNotifications: 'Add Notifications',
    sendToAllUsers: 'Send to all users',
    notifyNow: 'Notify Now',
    notifyLater: 'Notify Later',
    selectDate: 'Select Date',
    message: 'Message',
    status: 'Status',
    date: 'Date'
  }
}
