import { getApiClientWithAuth } from '../../common/services/api-service'
import { ISuccessResponse } from '../../common/types/ISuccessResponse'
import {
  IContactUsFormSubmissionRequest,
  IContactUsFormTokenReq
} from '../types/IContactUsSubmissionRequest'
import { IContactUsFormRequest } from '../types/IContactUsFormRequest'
import {
  ICaptchaResponse,
  IContactUsFormResponse
} from '../types/IContactUsFormResponse'
import { IErrorResponse } from '../../common/types/IErrorResponse'
import { IContactUsFormTokenResponse } from '../types/IContactUsSubmissionResponse'
import { IContactUsMessageResponse } from '../types/IContactUsMessageResponse'
import { IListResponse } from '../../common/types/IListResponse'
import { IContactUsMessageReq } from '../types/IContactUsMessageRequest'

export const getContactUsForms = async (): Promise<
  IContactUsFormResponse[]
> => {
  try {
    const response = await getApiClientWithAuth().get(
      '/api/v1/contact-us-forms'
    )
    return response.data
  } catch (error) {
    throw error
  }
}

export const submitContactForm = async (
  contactFormRequest: IContactUsFormSubmissionRequest
): Promise<IContactUsFormResponse> => {
  try {
    const response = await getApiClientWithAuth().post(
      '/api/v1/contact-us-form-messages',
      contactFormRequest
    )
    return response.data
  } catch (error) {
    throw error
  }
}

export const updateContactUsForm = async (
  formId: string,
  contactDetailReq: IContactUsFormRequest
): Promise<IContactUsFormResponse> => {
  try {
    const response = await getApiClientWithAuth().put(
      `/api/v1/contact-us-forms/${formId}`,
      contactDetailReq
    )
    return response.data
  } catch (error) {
    throw error
  }
}

export const addContactUsForm = async (
  contactDetailReq: IContactUsFormRequest
): Promise<IContactUsFormResponse> => {
  try {
    const response = await getApiClientWithAuth().post(
      '/api/v1/contact-us-forms',
      contactDetailReq
    )
    return response.data
  } catch (error) {
    throw error
  }
}

export const getContactUsFormById = async (
  formId: string
): Promise<IContactUsFormResponse> => {
  try {
    const response = await getApiClientWithAuth().get(
      `/api/v1/contact-us-forms/${formId}`
    )
    return response.data
  } catch (error) {
    throw error
  }
}

export const getContactUsMessageById = async (
  contactReq: IContactUsMessageReq
): Promise<IListResponse<IContactUsMessageResponse>> => {
  try {
    const response = await getApiClientWithAuth().get(
      `/api/v1/contact-us-form-messages${contactReq.params}&spam-detected=${contactReq.isSpam}&form-id=${contactReq.formId}&sort-column=createdAt&sort-dir=-1`
    )
    return response.data
  } catch (error) {
    throw error
  }
}

export const deleteContactUsMessageById = async (
  formId: string
): Promise<ISuccessResponse | IErrorResponse> => {
  try {
    const response = await getApiClientWithAuth().delete(
      `/api/v1/contact-us-form-messages/${formId}`
    )
    return response.data
  } catch (error) {
    throw error
  }
}

export const createContactUsFormToken = async (
  contactUsFormTokenReq: IContactUsFormTokenReq
): Promise<IContactUsFormTokenResponse> => {
  try {
    const response = await getApiClientWithAuth().post(
      '/api/v1/create-contact-us-form-token',
      contactUsFormTokenReq
    )
    return response.data
  } catch (error) {
    throw error
  }
}

export const getCaptchaImage = async (
  captchaTokenReq: string
): Promise<ICaptchaResponse> => {
  try {
    const response = await getApiClientWithAuth().get(
      `/api/v1/captcha-image/token/${captchaTokenReq}`
    )
    return response.data
  } catch (error) {
    throw error
  }
}

export const deleteContactUsFormById = async (
  formId: string
): Promise<ISuccessResponse | IErrorResponse> => {
  try {
    const response = await getApiClientWithAuth().delete(
      `/api/v1/contact-us-forms/${formId}`
    )
    return response.data
  } catch (error) {
    throw error
  }
}

export const getContactUsMessage = async (
  messageId: string
): Promise<IContactUsMessageResponse> => {
  try {
    const response = await getApiClientWithAuth().get(
      `/api/v1/contact-us-form-messages/${messageId}`
    )
    return response.data
  } catch (error) {
    throw error
  }
}
